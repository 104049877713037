export const environment = {
  production: true,
  apiUrl: 'https://oxzasoxhqaap01.azurewebsites.net/v1',
  auth0: {
    domain: 'login-qa.ganacontuinmueble.com',
    clientId: 'rjLRljrIxf4SDLZhLfNE1bwHcoVYmyGB',
    authorizationParams: {
      audience: 'https://login-qa.ganacontuinmueble.com/api/v2',
      redirect_uri: 'https://qa.ganacontuinmueble.com/callback',
    },
    errorPath: '/callback',
  },
  imgStorage: 'https://oxzstaoxhqaws01.blob.core.windows.net',
  mapsApiKey: 'AIzaSyA86xWnntLR07iaWDosllXhZPZPINzpKT0',
  logoExternos: 'https://oxzstaoxhqaws01.blob.core.windows.net/oxzbloboxhqa02/logo_1.png',
};
